import { actionTypes } from '../actions';
import initialState from '../initial-state';

export default function ensoReducer(state = initialState.enso, action) {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                loggedIn: action.loggedIn,
            };
        case actionTypes.INITIAL_STATE_SUCCESS:
            return {
                ...state,
                orgState: action.ensoState,
            };
        case actionTypes.UPDATE_REGISTRATION:
            return {
                ...state,
                registration: action.registrationFields,
            };
        case actionTypes.ADD_TO_TOAST_QUEUE:
            return {
                ...state,
                toastQueue: [...state.toastQueue, action.toast],
            };
        case actionTypes.CLEAR_TOAST_QUEUE:
            return {
                ...state,
                toastQueue: [],
            };
        default:
            return { ...state };
    }
}
