import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import initialState from './initial-state';
import rootReducer from './reducers/root-reducer';
import { setSessionStorage } from './session-storage';

const storeCreator = () => {
    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(thunk),
    );
    store.subscribe(() => {
        const { registration, orgState, loggedIn } = store.getState().enso;
        setSessionStorage('enso', { registration, orgState, loggedIn });
    });
    return store;
};

export default storeCreator;
