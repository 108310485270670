export const getSessionStorage = name => {
    try {
        return JSON.parse(window.sessionStorage.getItem(name));
    } catch (e) {
        return undefined;
    }
};

export const setSessionStorage = (name, value) => {
    try {
        window.sessionStorage.setItem(name, JSON.stringify(value));
    } catch (e) {
        console.warn(`Unable to save state to local storage: ${e}`);
    }
};
