import { API_URL } from '../lib/utils';

const prefix = '@enso-data-ui';

export const actionTypes = {
    INITIAL_STATE: `${prefix}/INITIAL_STATE`,
    INITIAL_STATE_SUCCESS: `${prefix}/INITIAL_STATE_SUCCESS`,
    LOGIN_SOURCE_SUCCESS: `${prefix}/LOGIN_SOURCE_SUCCESS`,
    LOGIN_TARGET_SUCCESS: `${prefix}/LOGIN_TARGET_SUCCESS`,
    UPDATE_REGISTRATION: `${prefix}/UPDATE_REGISTRATION`,
    LOGIN: `${prefix}/LOGIN`,
    ADD_TO_TOAST_QUEUE: `${prefix}/ADD_TO_TOAST_QUEUE`,
    CLEAR_TOAST_QUEUE: `${prefix}/CLEAR_TOAST_QUEUE`,
};

export const loadState = () => async dispatch => {
    const response = await ensoFetch(dispatch, '/state');
    const state = await response.json();
    dispatch(loadStateSuccess(state));
    return state;
};

export function loadStateSuccess(ensoState) {
    return { type: actionTypes.INITIAL_STATE_SUCCESS, ensoState };
}

export function updateRegistration(registrationFields) {
    return { type: actionTypes.UPDATE_REGISTRATION, registrationFields };
}

export const startMigration = registration => dispatch => {
    return (
        ensoFetch(dispatch, '/migration', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(registration),
        })
        .catch(error => {
            alert(`Failure while trying to start migration: ${error}`);
            throw error;
        })
    );
};

export function setLoginState(loggedIn) {
    return { type: actionTypes.LOGIN, loggedIn };
}

/**
 * Accepts same arguments as `addToast` from `react-toast-notifications`.
 */
export function addToast(...toast) {
    return { type: actionTypes.ADD_TO_TOAST_QUEUE, toast };
}

export function clearToastQueue() {
    return { type: actionTypes.CLEAR_TOAST_QUEUE };
}

async function ensoFetch(dispatch, path, options = {}) {
    let response = null;
    try {
        response = await fetch(API_URL + path, {
            credentials: 'include',
            mode: 'cors',
            ...options,
        });
    } catch (error) {
        const messagePrefix = 'Error while making API call:';
        console.warn(messagePrefix, error);
        dispatch(addToast(
            `${messagePrefix} ${error.message}`,
            { appearance: 'error' },
        ));
        // Log user out if the backend cannot be reached.
        dispatch(setLoginState(false))
        throw error;
    }
    if (response.status === 401) {
        dispatch(setLoginState(false));
        throw new Error('Unauthorized');
    } else if (response.status !== 200) {
        const message = `Received ${response.status} status code: ` +
            await response.text();
        console.warn(message);
        throw new Error(message);
    }
    return response;
}
