import { getSessionStorage } from './session-storage';

export default {
    enso: {
        registration: {},
        orgState: {},
        loggedIn: null,
        // Toast data waiting to be displayed on the next render.
        toastQueue: [],
        ...getSessionStorage('enso'),
    },
};
