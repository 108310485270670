import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import createStore from '../state/store';

/**
 * Wraps Gatsby root element into a Redux provider.
 */
const RootWrapper = ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore();
    return <Provider store={store}>{element}</Provider>;
};

RootWrapper.propTypes = {
    element: PropTypes.element.isRequired,
};

export default RootWrapper;
