import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../state/actions';
import { ToastProvider, useToasts } from 'react-toast-notifications';

/**
 * Wraps every single page component.
 *
 * - Makes call to fetch state from backend every time the application loads.
 * - Makes call to Google Analytics for every page view.
 */
function Page({
    toastQueue,
    loadServerState,
    clearToastQueue,
    page,
    props,
}) {
    const url = props.location.href;
    // This callback fires once per URL change (i.e. page view).
    useEffect(
        () => {
            callGoogleAnalytics(url);
        },
        [url],
    );
    // This callback fires once per application load (i.e. first-time load
    // or page reload).
    useEffect(
        () => {
            loadServerState().catch(() => {});
        },
        [loadServerState],
    );
    return (
        <ToastProvider autoDismiss>
            <ToastProcessor
                toastQueue={toastQueue}
                clearToastQueue={clearToastQueue}
            />
            {page}
        </ToastProvider>
    );
}

function ToastProcessor({ toastQueue, clearToastQueue }) {
    const { addToast } = useToasts();
    useEffect(
        () => {
            if (toastQueue.length) {
                toastQueue.forEach(toast => {
                    addToast(...toast);
                });
                clearToastQueue();
            }
        },
        [toastQueue, addToast, clearToastQueue],
    );
    return null;
}

function callGoogleAnalytics(url) {
    // TODO: Place Google Analytics code here to register page view.
}

const ConnectedPage = connect(
    ({ enso: { toastQueue } }) => ({ toastQueue }),
    {
        loadServerState: actions.loadState,
        clearToastQueue: actions.clearToastQueue,
    },
)(Page);

export default function PageWrapper({ element, props }) {
    // It's better to not have the flicker when a page hydrated at runtime does
    // not match its pre-rendered state at build time by simply returning null
    // for the build-time render state.
    if (typeof window === 'undefined') {
        return null;
    }
    return <ConnectedPage page={element} props={props} />;
}
