/**
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { navigate } from 'gatsby';

export { default as wrapRootElement } from './src/components/root-wrapper';

export { default as wrapPageElement } from './src/components/page-wrapper';

let anchor;

export function onPreRouteUpdate({ location }) {
    // Force trailing slash on all routes.
    if (!location.pathname.endsWith('/')) {
        if (!anchor) {
            anchor = document.createElement('a');
        }
        anchor.href = location.href;
        anchor.pathname += '/';
        navigate(anchor.href.slice(anchor.origin.length), {
            replace: true,
            state: location.state,
        });
    }
}
