import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ensoReducer from './enso-reducer';

const rootReducer = combineReducers({
    enso: ensoReducer,
    form: formReducer,
});

export default rootReducer;
